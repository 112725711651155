<template>
    <div class="tooltip-container">
      <div class="tooltip-content">
        {{ text }}
      </div>
    </div>
  </template>
  
  <script>
import { defineComponent, ref, onMounted, onUnmounted, nextTick } from 'vue'
  
export default defineComponent({
    props: {
      text: {
        type: String,
        required: true
      }
    },

    setup(props) {
        const showTooltip = ref(false);
        let parent = null;
  
        const onMouseEnter = () => {
            showTooltip.value = true;
        }
  
        const onMouseLeave = () => {
            showTooltip.value = false;
        }
  
        onMounted(() => {
            nextTick(() => {
                parent = document.querySelector('.tooltip-parent')
                if (parent) {
                    parent.addEventListener('mouseenter', onMouseEnter);
                    parent.addEventListener('mouseleave', onMouseLeave);
                }
            });
        });
  
        onUnmounted(() => {
            if (parent) {
                parent.removeEventListener('mouseenter', onMouseEnter);
                parent.removeEventListener('mouseleave', onMouseLeave);
            }
        });
  
        return {
            showTooltip
        }
    }
});
</script>
  
<style scoped>
.tooltip-container {
    position: relative;
}
  
.tooltip-content {
    position: absolute;
    top: 105%;
    padding: 0.5rem;
    margin: 0.5rem;
    font-size: 10px;
    background-color: #495057;
    color: white;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 9999;
    animation: tooltips-vert 400ms ease-out forwards;
}
  
.tooltip-container:hover .tooltip-content {
    opacity: 1;
}
</style>