<template>
  <CardGroup>
    <div class="forcast-overview">
      <sdCards>
        <template #button>
          <RangePicker :timeline="interval" />
          <div class="card-radio">
            <a-radio-group @change="onChangeInterval" v-model:value="interval">
              <a-radio-button value="day">Today</a-radio-button>
              <a-radio-button value="week">Week</a-radio-button>
              <a-radio-button value="month">Month</a-radio-button>
              <a-radio-button value="year">Year</a-radio-button>
            </a-radio-group>
          </div>
        </template>

        <div v-if="isLoading" class="sd-spin">
          <a-spin />
        </div>

        <a-row v-else-if="! isLoading" :gutter="25">
          <a-col :xl="12" :md="12">
            <a-row class="focard-wrapper focard-divider">
              <a-col :md="12" :sm="12" :xs="24">
                <Focard>
                  <sdHeading as="h6">
                    <!--<Tooltip text="Aceasta este o descriere" />-->
                    ATTENTION
                    <!--<sdFeatherIcons type="help-circle" size="15" />-->
                  </sdHeading>
                  <div class="focard-details growth-upward">
                    <sdHeading as="h1">{{ mockVisitorsBehavior.sessions }}</sdHeading>
                    <p class="subtitle">Delivered pop-ups</p>
                  </div>
                  <div class="focard-chart">
                    <sdChartContainer class="parentContainer">
                      <Chart
                        type="line"
                        :labels="mockVisitorsBehaviorLabels.sessions.labels"
                        :options="areaChartOption"
                        :style="{
                          marginBottom: '0',
                        }"
                        :datasets="[
                          {
                            label: '',
                            data: mockVisitorsBehaviorLabels.sessions.value,
                            borderColor: '#20C997',
                            borderWidth: 3,
                            fill: true,
                            backgroundColor: () => chartMethods('sessions', ['#20C99710', '#20C99701']),
                            pointHoverRadius: 0,
                            pointHoverBorderColor: 'transparent',
                          },
                        ]"
                        :height="height"
                        class="sessions"
                        id="sessions"
                      />
                    </sdChartContainer>
                  </div>
                </Focard>
              </a-col>
              <a-col :md="12" :sm="12" :xs="24">
                <Focard>
                  <sdHeading as="h6">INTEREST</sdHeading>
                  <div class="focard-details growth-upward">
                    <sdHeading as="h1">{{ mockVisitorsBehavior.interactions }}</sdHeading>
                    <p class="subtitle">Interactions</p>
                  </div>
                  <div class="focard-chart">
                    <sdChartContainer class="parentContainer">
                      <Chart
                        type="line"
                        id="impression"
                        class="impression"
                        :labels="mockVisitorsBehaviorLabels.interactions.labels"
                        :options="areaChartOption"
                        :style="{
                          marginBottom: '0',
                        }"
                        :datasets="[
                          {
                            label: '',
                            data: mockVisitorsBehaviorLabels.interactions.value,
                            borderColor: '#FF69A5',

                            borderWidth: 3,
                            fill: true,
                            backgroundColor: () => chartMethods('impression', ['#FF69A510', '#FF69A501']),
                            pointHoverRadius: 0,
                            pointHoverBorderColor: 'transparent',
                          },
                        ]"
                        :height="height"
                      />
                    </sdChartContainer>
                  </div>
                </Focard>
              </a-col>
              <a-col :md="12" :sm="12" :xs="24">
                <Focard>
                  <div class="focard-details growth-upward">
                    <sdHeading as="h1">{{ mockVisitorsBehavior.dismissed }}</sdHeading>
                    <p class="subtitle">Dismissed pop-ups</p>
                  </div>
                  <div class="focard-chart">
                    <sdChartContainer class="parentContainer">
                      <Chart
                        type="line"
                        :labels="mockVisitorsBehaviorLabels.dismissed.labels"
                        :options="areaChartOption"
                        :style="{
                          marginBottom: '0',
                        }"
                        :datasets="[
                          {
                            label: '',
                            data: mockVisitorsBehaviorLabels.dismissed.value,
                            borderColor: '#20C997',
                            borderWidth: 3,
                            fill: true,
                            backgroundColor: () => chartMethods('dismissedpopups', ['#20C99710', '#20C99701']),
                            pointHoverRadius: 0,
                            pointHoverBorderColor: 'transparent',
                          },
                        ]"
                        :height="height"
                        class="dismissedpopups"
                        id="dismissedpopups"
                      />
                    </sdChartContainer>
                  </div>
                </Focard>
              </a-col>
              <a-col :md="12" :sm="12" :xs="24">
                <Focard>
                  <div class="focard-details growth-upward">
                    <sdHeading as="h1">{{ mockVisitorsBehavior.gammified_recommendation }}</sdHeading>
                    <p class="subtitle">Gamified recommendations</p>
                  </div>
                  <div class="focard-chart">
                    <sdChartContainer class="parentContainer">
                      <Chart
                        type="line"
                        id="gamification"
                        class="gamification"
                        :labels="mockVisitorsBehaviorLabels.gammified_recommendation.labels"
                        :options="areaChartOption"
                        :style="{
                          marginBottom: '0',
                        }"
                        :datasets="[
                          {
                            label: '',
                            data: mockVisitorsBehaviorLabels.gammified_recommendation.value,
                            borderColor: '#FF69A5',

                            borderWidth: 3,
                            fill: true,
                            backgroundColor: () => chartMethods('gamification', ['#FF69A510', '#FF69A501']),
                            pointHoverRadius: 0,
                            pointHoverBorderColor: 'transparent',
                          },
                        ]"
                        :height="height"
                      />
                    </sdChartContainer>
                  </div>
                </Focard>
              </a-col>
            </a-row>
          </a-col>
          <a-col :xl="12" :xs="24">
            <a-row class="focard-wrapper">
              <a-col :md="12" :sm="12">
                <Focard>
                  <sdHeading as="h6">DESIRE</sdHeading>
                  <div class="focard-details growth-downward">
                    <sdHeading as="h1">{{ mockVisitorsBehavior.like }}</sdHeading>
                    <p class="subtitle">Liked products</p>
                  </div>
                  <div class="focard-chart">
                    <sdChartContainer class="parentContainer">
                      <Chart
                        type="line"
                        :labels="mockVisitorsBehaviorLabels.like.labels"
                        :options="areaChartOption"
                        :style="{
                          marginBottom: '0',
                        }"
                        :datasets="[
                          {
                            data: mockVisitorsBehaviorLabels.like.value,
                            label: '',
                            borderColor: '#5F63F2',
                            borderWidth: 3,
                            fill: true,
                            backgroundColor: () => chartMethods('likes', ['#5F63F210', '#5F63F201']),
                            pointHoverRadius: 0,
                            pointHoverBorderColor: 'transparent',
                          },
                        ]"
                        :height="height"
                        id="likes"
                        class="likes"
                      />
                    </sdChartContainer>
                  </div>
                </Focard>
              </a-col>
              <a-col :md="12" :sm="12" :xs="24">
                <Focard>
                  <sdHeading as="h6">ACTION</sdHeading>
                  <div class="focard-details growth-upward">
                    <sdHeading as="h1">{{ mockVisitorsBehavior.share }}</sdHeading>
                    <p class="subtitle">Saved for later / Shared products</p>
                  </div>
                  <div class="focard-chart">
                    <sdChartContainer class="parentContainer">
                      <Chart
                        type="line"
                        :labels="mockVisitorsBehaviorLabels.share.labels"
                        :options="areaChartOption"
                        :style="{
                          marginBottom: '0',
                        }"
                        :datasets="[
                          {
                            data: mockVisitorsBehaviorLabels.share.value,
                            label: '',
                            borderColor: '#FA8B0C',
                            borderWidth: 3,
                            fill: true,
                            backgroundColor: () => chartMethods('share', ['#FA8B0C10', '#FA8B0C01']),
                            pointHoverRadius: 0,
                            pointHoverBorderColor: 'transparent',
                          },
                        ]"
                        :height="height"
                        id="share"
                        class="share"
                      />
                    </sdChartContainer>
                  </div>
                </Focard>
              </a-col>
              <a-col :md="12" :sm="12">
                <Focard>
                  <div class="focard-details growth-downward">
                    <sdHeading as="h1">{{ mockVisitorsBehavior.accessed_product_on_popup }}</sdHeading>
                    <p class="subtitle">Accesed products</p>
                  </div>
                  <div class="focard-chart">
                    <sdChartContainer class="parentContainer">
                      <Chart
                        type="line"
                        :labels="mockVisitorsBehaviorLabels.accessed_product_on_popup.labels"
                        :options="areaChartOption"
                        :style="{
                          marginBottom: '0',
                        }"
                        :datasets="[
                          {
                            data: mockVisitorsBehaviorLabels.accessed_product_on_popup.value,
                            label: '',
                            borderColor: '#5F63F2',
                            borderWidth: 3,
                            fill: true,
                            backgroundColor: () => chartMethods('accessedproducts', ['#5F63F210', '#5F63F201']),
                            pointHoverRadius: 0,
                            pointHoverBorderColor: 'transparent',
                          },
                        ]"
                        :height="height"
                        id="accessedproducts"
                        class="accessedproducts"
                      />
                    </sdChartContainer>
                  </div>
                </Focard>
              </a-col>
              <a-col :md="12" :sm="12" :xs="24">
                <Focard>
                  <div class="focard-details growth-upward">
                    <sdHeading as="h1">{{ mockVisitorsBehavior.purchased_products }}</sdHeading>
                    <p class="subtitle">Purchased products</p>
                  </div>
                  <div class="focard-chart">
                    <sdChartContainer class="parentContainer">
                      <Chart
                        type="line"
                        :labels="mockVisitorsBehaviorLabels.purchased_products.labels"
                        id="purchasedProducts"
                        class="purchasedProducts"
                        :options="areaChartOption"
                        :style="{
                          marginBottom: '0',
                        }"
                        :datasets="[
                          {
                            data: mockVisitorsBehaviorLabels.purchased_products.value,
                            label: '',
                            borderColor: '#FA8B0C',
                            borderWidth: 3,
                            fill: true,
                            backgroundColor: () => chartMethods('purchasedProducts', ['#FA8B0C10', '#FA8B0C01']),
                            pointHoverRadius: 0,
                            pointHoverBorderColor: 'transparent',
                          },
                        ]"
                        :height="height"
                      />
                    </sdChartContainer>
                  </div>
                </Focard>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </sdCards>
    </div>
  </CardGroup>
</template>

<script>
import { Focard, CardGroup } from '../styled_reports';
import { DatePickerWrapper } from '../../styled';
import Chart from '../../../components/utilities/chartjs';
import { chartLinearGradient, customTooltips } from '../../../components/utilities/utilities';
import { computed, onMounted, ref, defineComponent, provide } from 'vue';
import { useStore } from 'vuex';
import ReportsService from '../../../services/ReportsService';
import Tooltip from '../../../components/tooltip/Tooltip.vue';
import RangePicker from '../../../components/custom/date/RangePicker.vue';

export default defineComponent({
  name: 'VisitsBehaviourTable',
  
  components: {
    Focard,
    CardGroup,
    Chart,
    Tooltip,
    DatePickerWrapper,
    RangePicker
  },

  setup() {
    const interval = ref('day');
    const height = ref(null);
    const { dispatch, state } = useStore();

    const mockVisitorsBehavior = {
      like: ref(0),
      dislike: ref(0),
      share: ref(0),
      buy_later: ref(0),
      accessed_product_on_popup: ref(0),
      dismissed: ref(0),
      sessions: ref(0),
      gammified_recommendation: ref(0),
      interactions: ref(0),
      purchased_products: ref(0)
    };

    const mockVisitorsBehaviorLabels = {
      like: {
        labels: ref([1, 2, 3, 4, 5, 6, 7]),
        value: ref([0, 0, 0, 0, 0, 0, 0])
      },
      dislike: {
        labels: ref([1, 2, 3, 4, 5, 6, 7]),
        value: ref([0, 0, 0, 0, 0, 0, 0])
      },
      share: {
        labels: ref([1, 2, 3, 4, 5, 6, 7]),
        value: ref([0, 0, 0, 0, 0, 0, 0])
      },
      buy_later: {
        labels: ref([1, 2, 3, 4, 5, 6, 7]),
        value: ref([0, 0, 0, 0, 0, 0, 0])
      },
      accessed_product_on_popup: {
        labels: ref([1, 2, 3, 4, 5, 6, 7]),
        value: ref([0, 0, 0, 0, 0, 0, 0])
      },
      dismissed: {
        labels: ref([1, 2, 3, 4, 5, 6, 7]),
        value: ref([0, 0, 0, 0, 0, 0, 0])
      },
      sessions: {
        labels: ref([1, 2, 3, 4, 5, 6, 7]),
        value: ref([0, 0, 0, 0, 0, 0, 0])
      },
      gammified_recommendation: {
        labels: ref([1, 2, 3, 4, 5, 6, 7]),
        value: ref([0, 0, 0, 0, 0, 0, 0])
      },
      interactions: {
        labels: ref([1, 2, 3, 4, 5, 6, 7]),
        value: ref([0, 0, 0, 0, 0, 0, 0])
      },
      purchased_products: {
        labels: ref([1, 2, 3, 4, 5, 6, 7]),
        value: ref([0, 0, 0, 0, 0, 0, 0])
      }
    }

    onMounted(async () => {
      height.value = window.innerWidth <= 1199 ? 100 : 115;
      await getVisitorsBehavior();
    });

    const isLoading = computed(() => state.actions.isLoading);

    const getVisitorsBehavior = async (
      interval = 'day', 
      start = null, 
      end = null, 
      timeRange = false
    ) => {
      dispatch("actions/setLoading", true);

      let response = {};

      if (! timeRange) response = await ReportsService.getVisitorsBehavior(interval);
      else response = await ReportsService.getVisitorsBehavior(interval, start, end, timeRange);

      console.log(response);

      const actions = response.data.actions;

      actions.forEach((action) => {
        const lowerCaseType = action.type.toLowerCase();
        if (mockVisitorsBehavior[lowerCaseType]) {
          mockVisitorsBehavior[lowerCaseType].value = action._count;
        }

        if (lowerCaseType === 'buy_later') {
          mockVisitorsBehavior['share'].value += action._count;
        }

        if (lowerCaseType === 'like' || lowerCaseType === 'dislike') {
          mockVisitorsBehavior['gammified_recommendation'].value += action._count;
        }
      });

      const chartDataKeys = Object.keys(response.data.chart_data);

      chartDataKeys.forEach((key) => {
        const keyLowerCase = key.toLowerCase();

        if (mockVisitorsBehaviorLabels[keyLowerCase]) {
          const labels = Object.keys(response.data.chart_data[key].labels);
          const values = Object.values(response.data.chart_data[key].labels);
          mockVisitorsBehaviorLabels[keyLowerCase].labels.value = labels;
          mockVisitorsBehaviorLabels[keyLowerCase].value  = values;
        }
      });

      dispatch("actions/setLoading", false);
    }

    const chartMethods = (elementId, color) => {
      return chartLinearGradient(document.querySelector(`.${elementId}`), 165, {
        start: color[0],
        end: color[1],
      });
    };

    const resetMockValues = () => {
      for (let key in mockVisitorsBehavior) {
        mockVisitorsBehavior[key].value = 0;
      }
    }

    const onChangeInterval = (e) => {
      resetMockValues();
      getVisitorsBehavior(e.target.value);
    };

    const handleChangeRangeEvent = (range) => {
      if (range[0] === '' && range[1] === '') return;
      resetMockValues();
      getVisitorsBehavior("", range[0], range[1], true);
    };

    provide('emitEvent', (event, data) => {
      handleChangeRangeEvent(data);
    });

    return {
      interval,
      isLoading,
      height,
      mockVisitorsBehavior,
      mockVisitorsBehaviorLabels,
      chartMethods,
      onChangeInterval,
      areaChartOption: {
        maintainAspectRatio: true,
        responsive: false,
        hover: {
          mode: 'nearest',
          intersect: false,
        },
        layout: {
          padding: {
            left: -10,
            right: 0,
            top: 2,
            bottom: -10,
          },
        },
        legend: {
          display: false,
          labels: {
            display: false,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
                color: '#e5e9f2',
              },
              ticks: {
                beginAtZero: true,
                fontSize: 10,
                display: false,
                stepSize: 20,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },

              ticks: {
                beginAtZero: true,
                fontSize: 11,
                display: false,
              },
            },
          ],
        },
        tooltips: {
          mode: 'label',
          intersect: false,
          position: 'average',
          enabled: false,
          custom: customTooltips,
          callbacks: {
            label(t, d) {
              const dstLabel = d.datasets[t.datasetIndex].label;
              const { yLabel } = t;
              return `<span class="chart-data">${yLabel}</span> <span class="data-label">${dstLabel}</span>`;
            },
            labelColor(tooltipItem, chart) {
              const dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
              return {
                backgroundColor: dataset.borderColor,
                borderColor: 'transparent',
                usePointStyle: true,
              };
            },
          },
        },
      },
    };
  },
});
</script>
